import React from 'react';
// import styled from '@emotion/styled';
import { Flex } from 'theme-ui';
import Layout from '../components/layout';

const Contact = () => {
  return (
    <Layout>
      <Flex>
        <h1>contact</h1>
      </Flex>
    </Layout>
  );
};

export default Contact;
